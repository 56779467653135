const fadeDuration = 500;
const level2Menu = ['visitor', 'bike', 'walk', 'ornito', 'viewpoint', 'dock', 'waterLife', 'flooding']

export class Menu {
    constructor(windowScene) {
        this.scene = windowScene;
        this.state = 'lang';
        this.fadeDuration = 500;
        this.zoomDelay = 2000;

        $('#menu-language').fadeIn(this.fadeDuration);
    }

    setMenuVisibility(nextMenu) {
        $('.activeMenu').fadeOut(this.fadeDuration);
        setTimeout(() => {
            $(nextMenu).fadeIn(this.fadeDuration);
        }, 500);
    }

    handleMenuClick(menu, value, layer = false, pins = false, opacity = false) {
        switch (menu) {
            case 'lang':
                this.state = 'story';
                this.setLanguage(value);
                this.hideMenu('language');

                this.canvasZoomIn();
                this.showMenu('story', this.zoomDelay);
                this.showBack(this.zoomDelay);

                this.showBaseLayers();
                this.toggleCompass(true);
                break;

            case 'floodEcosystem':
                this.hideMenu(menu);
                this.state = value;
                this.showOverlay(value, this.fadeDuration);
                this.hideBaseLayers();

                if (value === 'flooding') {
                    this.scene.setProgress(0);
                    this.scene.resume();
                    this.activateSlider();
                    this.canvasZoomOut(this.fadeDuration);
                }
                break;

            default:
                this.hideMenu(menu);
                this.state = value;

                this.showMenu(value, this.fadeDuration);

                if (value === 'bike') {
                    window.scene.zoomInBike();
                }

                if (value == 'geo') {
                    this.togglePins('sisak', 0);
                }

                if (layer) {
                    window.scene.setLayerOpacity(layer, opacity);
                    this.hideBaseLayers();
                }

                if (pins) {
                    this.togglePins(pins, 1);
                    this.hideBaseLayers();
                }

                break;
        }
    }

    handleBack() {
        this.closePopover();

        switch (this.state) {
            case 'story':
                this.hideMenu(this.state);
                this.state = 'lang';
                this.hideBack(0);

                this.canvasZoomOut(this.fadeDuration);
                this.showMenu('language', this.zoomDelay);

                this.hideBaseLayers();
                this.toggleCompass(false);

                break;

            case 'geo':
                this.hideMenu(this.state);
                this.state = 'story';
                this.showMenu('story', this.fadeDuration);
                this.togglePins('sisak', 1);

                break;

            case 'visitor': case 'bike': case 'walk': case 'ornito': case 'viewpoint': case 'dock':
                window.scene.setLayerOpacity('layer_' + this.state, 0);
                window.scene.zoomIn();
                this.clearBikeRoutes();
                this.clearWalkRoutes();
                this.clearOrnito();
                this.clearPins();
                this.clearActive();

                $('.popover').fadeOut(this.fadeDuration);
                this.showBaseLayers();
                this.togglePins('sisak', 0);

                this.hideMenu(this.state);
                this.state = 'geo';
                this.showMenu(this.state, this.fadeDuration);

                break;

            case 'floodEcosystem':
                this.showBaseLayers();
                this.hideMenu(this.state);
                this.state = 'story';
                this.showMenu('story', this.fadeDuration);

                break;

            case 'waterLife':
                this.hideOverlay();
                this.closeVideo();
                this.showBaseLayers();
                this.state = 'floodEcosystem';
                this.showMenu('floodEcosystem', 0);
                break;

            case 'flooding':
                this.state = 'floodEcosystem';
                this.showMenu('floodEcosystem', 0);
                this.deactivateSlider();
                this.canvasZoomIn(this.fadeDuration);
                this.showBaseLayers();
                window.scene.setProgress(0.56);
                window.scene.pause(); 
                break;
        }
    }

    resetTable() {

        this.state = 'lang';
        this.hideAllMenu();
        this.hideBack(0);

        this.canvasZoomOut(this.fadeDuration);
        this.showMenu('language', this.zoomDelay);

        this.hideBaseLayers();
        this.toggleCompass(false);
        
        this.deactivateBackdrop();
        this.deactivateSlider();
        
        this.clearBikeRoutes();
        this.hideAllBikeRoutes();
        
        this.clearWalkRoutes();
        this.hideAllWalkRoutes();

        this.clearOrnito();
        this.hideAllOrnito();
        
        this.clearPins();
        this.clearOrnito();
        this.hideOverlay();
        
        this.closePopover();
        this.closeVideo();

        window.scene.setProgress(0.56);
        window.scene.pause();
    }

    showBack(timeout) {
        setTimeout(function () {
            $('#btnBack').fadeIn(this.fadeDuration);
            $('#btnHome').fadeIn(this.fadeDuration);
        }, timeout);
    }

    hideBack(timeout) {
        setTimeout(function () {
            $('#btnBack').fadeOut(this.fadeDuration);
            $('#btnHome').fadeOut(this.fadeDuration);
        }, timeout);
    }

    setLanguage(lang) {
        window.translator.translatePageTo(lang);
        window.scene.updatePinsLanguage(lang);
    }

    showMenu(menu, timeout, hand = true) {
        setTimeout(function () {
            $('#menu-' + menu).fadeIn(this.fadeDuration);
            if (hand) {
                $('.hand').fadeIn(this.fadeDuration);
            }
        }, timeout)
    }

    hideMenu(menu) {
        $('#menu-' + menu).fadeOut(this.fadeDuration);
        $('.hand').fadeOut(this.fadeDuration);
    }

    hideAllMenu() {
        $('.menu').fadeOut(this.fadeDuration);
        $('.hand').fadeOut(this.fadeDuration);
    }

    showOverlay(overlay, timeout) {
        setTimeout(function () {
            $('#overlay-' + overlay).fadeIn(this.fadeDuration);
        }, timeout)
    }

    hideOverlay() {
        $('.container-overlay').fadeOut(this.fadeDuration);
    }

    showBaseLayers() {
        this.togglePins('town', 1);
        this.togglePins('village', 1);
        window.scene.setLayerOpacity('layer_e70', 1);
    }

    hideBaseLayers() {
        this.togglePins('town', 0);
        this.togglePins('village', 0);
        window.scene.setLayerOpacity('layer_e70', 0);
    }

    showAllBikeRoutes() {
        window.scene.setLayerOpacity('layer_bike', 1);
        this.togglePins('town', 1);
    }

    hideAllBikeRoutes() {
        window.scene.setLayerOpacity('layer_bike', 0);
        this.togglePins('town', 0);
    }

    resetBikeRoutes() {
        this.clearBikeRoutes();
        this.deactivateBackdrop();

        window.scene.setLayerOpacity('layer_bike', 1);
    }

    clearBikeRoutes() {
        window.scene.setLayerOpacity('layer_bike-nacionalna-ruta', 0);
        window.scene.setLayerOpacity('layer_bike-smz-1', 0);
        window.scene.setLayerOpacity('layer_bike-ppl-1', 0);
        window.scene.setLayerOpacity('layer_bike-ppl-2', 0);
        window.scene.setLayerOpacity('layer_bike-ppl-3', 0);
        window.scene.setLayerOpacity('layer_bike-ppl-4', 0);
        window.scene.setLayerOpacity('layer_bike-mocvarni', 0);
        window.scene.setLayerOpacity('layer_bike-do-lonje', 0);
    }

    showAllWalkRoutes() {
        window.scene.setLayerOpacity('layer_walk', 1);
        this.togglePins('town', 1);
    }

    hideAllWalkRoutes() {
        window.scene.setLayerOpacity('layer_walk', 0);
        this.togglePins('town', 0);
    }

    resetWalkRoutes() {
        this.clearWalkRoutes();
        this.deactivateBackdrop();

        window.scene.setLayerOpacity('layer_walk', 1);
    }

    clearWalkRoutes() {
        window.scene.setLayerOpacity('layer_walk-granicara', 0);
        window.scene.setLayerOpacity('layer_walk-kosceva', 0);
        window.scene.setLayerOpacity('layer_walk-posavca', 0);
        window.scene.setLayerOpacity('layer_walk-tenina', 0);
    }

    showAllOrnito() {
        window.scene.setLayerOpacity('layer_ornito', 1);
    }

    hideAllOrnito() {
        window.scene.setLayerOpacity('layer_ornito', 0);
    }

    resetOrnito() {
        this.clearOrnito();
        this.deactivateBackdrop();

        window.scene.setLayerOpacity('layer_ornito', 1);
    }

    clearOrnito() {
        window.scene.setLayerOpacity('layer_ornito-rakita', 0);
        window.scene.setLayerOpacity('layer_ornito-krapje', 0);
    }

    togglePins(type, opacity = 0) {
        switch (type) {
            case 'town':
                window.scene.setPinOpacity('town_sisak', opacity);
                window.scene.setPinOpacity('town_popovaca', opacity);
                window.scene.setPinOpacity('town_kutina', opacity);
                window.scene.setPinOpacity('town_novska', opacity);
                break;
            case 'sisak':
                window.scene.setPinOpacity('town_sisak', opacity);
                break;
            case 'village':
                window.scene.setPinOpacity('town_struzec', opacity);
                window.scene.setPinOpacity('town_osekovo', opacity);
                window.scene.setPinOpacity('town_donja_gracanica', opacity);
                window.scene.setPinOpacity('town_repusnica', opacity);
                window.scene.setPinOpacity('town_veliko_svinjicko', opacity);
                window.scene.setPinOpacity('town_cigoc', opacity);
                window.scene.setPinOpacity('town_kraljeva_velika', opacity);
                window.scene.setPinOpacity('town_suvoj', opacity);
                window.scene.setPinOpacity('town_lonja', opacity);
                window.scene.setPinOpacity('town_trebez', opacity);
                window.scene.setPinOpacity('town_puska', opacity);
                window.scene.setPinOpacity('town_krapje', opacity);
                window.scene.setPinOpacity('town_jasenovac', opacity);
                window.scene.setPinOpacity('town_drenov_bok', opacity);
                window.scene.setPinOpacity('town_kosturica', opacity);
                window.scene.setPinOpacity('town_mlaka', opacity);
                break;
            case 'visitor':
                window.scene.setPinOpacity('visitor_osekovo', opacity);
                window.scene.setPinOpacity('visitor_repusnica', opacity);
                window.scene.setPinOpacity('visitor_cigoc', opacity);
                window.scene.setPinOpacity('visitor_krapje', opacity);
                break;
            case 'viewpoint':
                window.scene.setPinOpacity('viewpoint_osekovo_1', opacity);
                window.scene.setPinOpacity('viewpoint_osekovo_2', opacity);
                window.scene.setPinOpacity('viewpoint_repusnica', opacity);
                window.scene.setPinOpacity('viewpoint_cigoc', opacity);
                window.scene.setPinOpacity('viewpoint_krapje', opacity);
                window.scene.setPinOpacity('viewpoint_drenov', opacity);
                break;
            case 'dock':
                window.scene.setPinOpacity('dock_drenov', opacity);
                window.scene.setPinOpacity('dock_plesmo', opacity);
                break;
        }
    }

    clearPins() {
        this.togglePins('visitor', 0);
        this.togglePins('viewpoint', 0);
        this.togglePins('dock', 0);
    }

    activateVideo(id) {
        this.closeVideo();
        this.activateBackdrop();
        this.changeVolume('audioAmbient', 0);
        
        setTimeout(function() {
            $('body').addClass('activeOverlay');
        }, 100)

        $('#video-' + id).fadeIn(this.fadeDuration);
        $('#videoplayer-' + id).get(0).currentTime = 0;
        $('#videoplayer-' + id).get(0).play();
        $('#videoplayer-' + id).addClass('activePlayer');
        $('#videoplayer-' + id).on('ended',function(){
            $('#videoplayer-' + id).removeClass('activePlayer');
            $('#video-' + id).fadeOut(this.fadeDuration);
            window.menuControl.changeVolume('audioAmbient', 1);
            window.menuControl.deactivateBackdrop();
            $(this).unbind();
        });
    }

    closeVideo() {
        this.deactivateBackdrop();
        this.changeVolume('audioAmbient', 1);
        $('body').removeClass('activeOverlay');
        $('.video').fadeOut(this.fadeDuration);

        if ($('.activePlayer').length) {
            $('.activePlayer').get(0).pause();
            $('.activePlayer').get(0).currentTime = 0;
            $('.video video').removeClass('activePlayer');
        }
    }

    activatePopover(id, pin = false) {
        $('.popover').fadeOut(this.fadeDuration);
        
        this.setPopoverTop(id);
        this.activateBackdrop();
        this.toggleCompassVisibility(false);
        $('#popover-' + id).fadeIn(this.fadeDuration);
        $('body').addClass('activeOverlay');
        
        if (pin) {
            this.clearPins();
            window.scene.setPinOpacity(pin, 1);
        }
    }

    closePopover(type) {
        $('.popover').fadeOut(this.fadeDuration);
        $('.activatePopover').removeClass('active');
        $('body').removeClass('activeOverlay');

        this.deactivateBackdrop();

        switch (type) {
            case 'visitor': case 'viewpoint': case 'dock':
                this.togglePins(type, 1);
                this.toggleCompassVisibility(true);
                break;
            default:
                break;
        }
    }

    activateRoute(id, type) {
        $('.popover').fadeOut(this.fadeDuration);
        $('body').addClass('activeOverlay');
        
        this.setPopoverTop(id);
        this.toggleCompassVisibility(false);

        switch (type) {
            case 'bike':
                this.hideAllBikeRoutes();
                this.clearBikeRoutes();
                break;
            case 'walk':
                this.hideAllWalkRoutes();
                this.clearWalkRoutes();
                break;
            case 'ornito':
                this.hideAllOrnito();
                this.clearOrnito();
                break;
            default:
                break;
        }

        this.activateBackdrop();
        $('#popover-' + id).fadeIn(this.fadeDuration);
        window.scene.setLayerOpacity('layer_' + id, 1);
    }

    closeRoute(type) {
        $('.activateRoute').removeClass('active');
        $('.popover').fadeOut(this.fadeDuration);
        $('body').removeClass('activeOverlay');

        switch (type) {
            case 'bike':
                this.resetBikeRoutes();
                this.toggleCompassVisibility(true);
                break;
            case 'walk':
                this.resetWalkRoutes();
                this.toggleCompassVisibility(true);
                break;
            case 'ornito':
                this.resetOrnito();
                this.toggleCompassVisibility(true);
                break;
            default:
                break;
        }
    }

    clearActive() {
        $('.activatePopover').removeClass('active');
        $('.activateRoute').removeClass('active');
    }

    activateBackdrop() {
        window.scene.setLayerOpacity('black', 0.5);
    }

    deactivateBackdrop() {
        window.scene.setLayerOpacity('black', 0);
    }

    activateSlider() {
        $('.container-slider').fadeIn(this.fadeDuration);
    } 

    deactivateSlider() {
        $('.container-slider').fadeOut(this.fadeDuration);
    }

    changeVolume(element, value) {
        var audio = document.getElementById(element);
        audio.volume = value;
    }

    setPopoverTop(id) {
        var height = $('#popover-' + id).outerHeight();
        var val = (620 - height) / 2; 

        $('#popover-' + id).css('top', val);
    }

    toggleCompass(active) { 
        if (active) {
            $('.container-compass').fadeIn(this.fadeDuration);
            $('.container-map').fadeOut(this.fadeDuration);
            $('.container-logo .logo').addClass('reduce');
        } else {
            $('.container-map').fadeIn(this.fadeDuration);
            $('.container-compass').fadeOut(this.fadeDuration);
            $('.container-logo').fadeIn(this.fadeDuration);
            $('.container-logo .logo').removeClass('reduce');
        }
    }

    toggleCompassVisibility(value) {
        if (value) {
            $('.container-compass').fadeIn(this.fadeDuration);
            $('.container-logo').fadeIn(this.fadeDuration);
        } else {
            $('.container-compass').fadeOut(this.fadeDuration);
            $('.container-logo').fadeOut(this.fadeDuration);
        }
    }

    canvasZoomIn(timeout) {
        setTimeout(() => {
            this.scene.zoomIn();
        }, timeout);
    }

    canvasZoomOut(timeout) {
        setTimeout(() => {
            this.scene.zoomOut();
        }, timeout);
    }
}
