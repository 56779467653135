import './BasisTextureLoader.js';

const vertexShader = `
    varying vec2 vTexCoord;

    void main()
    {
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        vTexCoord = uv;
    }
`

function mod(n, m) {
    return ((n % m) + m) % m;
}

export class Scene {
    constructor(canvas) {
        this.setupCanvas(canvas);
        this.waterAlpha = 0.8;
        this.fadeDepth = 5.0;
        this.fadeAmount = 0.6;
        this.waterColor = '#008b9c';
        this.previousTimestamp = null;
        this.stormyness = 0;
        this.snowyness = 0;
        this.perlin = null;
        this.lightning = 0.5;
        this.clouddark = 0;

        this.speed = 0.001 / 30;
        this.cloudTime = 0;
        this.minorCloudTime = 0;
        this.cloudSpeedFactor = 1;
        this.progress = 0.5;
        this.waterLevel = 0;
        this.running = false;
        this.userTransition = false;
        this.mousePosition = THREE.Vector2();
        this.pins = {};
        this.onclicks = {};
        this.plane = null;

        this.layerNames = [
            'granice.png',
            'black.png',
            'layer_e70@0.07138,-0.1287,0.8576,0.7425.png',
            'layer_walk@-0.0516,-0.01906,0.1299,0.2581.png',
            'layer_walk-tenina@-0.005505,-0.1008,0.01866,0.02925.png',
            'layer_bike-do-lonje@-0.101,-0.1707,0.06792,0.06084.png',
            'layer_nacionalna_ruta_sava@-0.1027,-0.01818,0.2792,0.2557.png',
            'layer_viewpoint@-0.0891,-0.03871,0.2695,0.339.png',
            'layer_bike-smz-1@-0.08462,-0.07151,0.3142,0.3627.png',
            'layer_walk-kosceva@-0.0613,-0.1446,0.02687,0.02574.png',
            'layer_bike-ppl-1@-0.1346,-0.02296,0.03583,0.05616.png',
            'layer_bike-ppl-2@0.01614,0.02267,0.05076,0.08541.png',
            'layer_bike-ppl-3@-0.1316,-0.1414,0.106,0.1322.png',
            'layer_walk-posavca@-0.1018,-0.07882,0.02986,0.02808.png',
            'layer_bike-ppl-4@-0.01633,-0.0604,0.04329,0.1228.png',
            'layer_ornito-rakita@-0.05962,-0.0566,0.02687,0.03754.png',
            'layer_ornito-krapje@0.003266,0.08534,0.02239,0.03988.png',
            'layer_bike-nacionalna-ruta@-0.1029,-0.01828,0.2792,0.255.png',
            'layer_walk-granicara@0.001026,0.09082,0.02463,0.03744.png',
            'layer_bike@-0.08481,-0.07155,0.315,0.3636.png',
            'layer_gradovi_lokacije@-0.07585,-0.1152,0.3553,0.2933.png',
            'layer_bike-mocvarni@-0.0626,-0.1168,0.04702,0.09476.png',
            'layer_dock@-0.007744,0.09667,0.2209,0.03276.png',
            'layer_mjesta@-0.03573,-0.03812,0.2911,0.3543.png',
            'layer_visitor@-0.03182,-0.05249,0.209,0.3003.png',
            'layer_ornito@-0.03648,0.02434,0.07315,0.1619.png',
        ]
        this.layers = {};
        this.seasonNames = [
            'season_000.basis',
            'season_001.basis',
            'season_002.basis',
            'season_003.basis',
            'season_004.basis',
            'season_005.basis',
            'season_006.basis',
            'season_007.basis',
            'season_008.basis',
            'season_009.basis',
            'season_010.basis',
            'season_011.basis',
            'season_012.basis',
            'season_013.basis',
            'season_014.basis',
            'season_015.basis',
            'season_016.basis',
            'season_017.basis',
            'season_018.basis',
            'season_019.basis',
            'season_020.basis',
            'season_021.basis',
            'season_022.basis',
            'season_023.basis',
            'season_024.basis',
            'season_025.basis',
            'season_026.basis',
            'season_027.basis',
            'season_028.basis',
            'season_029.basis',
            'season_030.basis',
            'season_031.basis',
            'season_032.basis',
            'season_033.basis',
            'season_034.basis',
            'season_035.basis',
            'season_036.basis',
            'season_037.basis',
            'season_038.basis',
            'season_039.basis',
            'season_040.basis',
            'season_041.basis',
            'season_042.basis',
            'season_043.basis',
            'season_044.basis',
            'season_045.basis',
            'season_046.basis',
            'season_047.basis',
            'season_048.basis',
            'season_049.basis',
            'season_050.basis',
            'season_051.basis',
            'season_052.basis',
            'season_053.basis',
            'season_054.basis',
            'season_055.basis',
            'season_056.basis',
            'season_057.basis',
            'season_058.basis',
            'season_059.basis',
            'season_060.basis',
            'season_061.basis',
            'season_062.basis',
            'season_063.basis',
            'season_064.basis',
            'season_065.basis',
            'season_066.basis',
            'season_067.basis',
            'season_068.basis',
            'season_069.basis',
            'season_070.basis',
            'season_071.basis',
            'season_072.basis',
            'season_073.basis',
            'season_074.basis',
            'season_075.basis',
            'season_076.basis',
            'season_077.basis',
            'season_078.basis',
            'season_079.basis',
            'season_080.basis',
            'season_081.basis',
            'season_082.basis',
            'season_083.basis',
            'season_084.basis',
            'season_085.basis',
            'season_086.basis',
            'season_087.basis',
            'season_088.basis',
            'season_089.basis',
            'season_090.basis',
            'season_091.basis',
            'season_092.basis',
            'season_093.basis',
            'season_094.basis',
            'season_095.basis',
            'season_096.basis',
            'season_097.basis',
            'season_098.basis',
            'season_099.basis',
            'season_100.basis',
            'season_101.basis',
            'season_102.basis',
            'season_103.basis',
            'season_104.basis',
            'season_105.basis',
            'season_106.basis',
            'season_107.basis',
            'season_108.basis',
            'season_109.basis',
            'season_110.basis',
            'season_111.basis',
            'season_112.basis',
            'season_113.basis',
            'season_114.basis',
            'season_115.basis',
            'season_116.basis',
            'season_117.basis',
            'season_118.basis',
            'season_119.basis',
            'season_120.basis',
            'season_121.basis',
            'season_122.basis',
            'season_123.basis',
            'season_124.basis',
            'season_125.basis',
            'season_126.basis',
            'season_127.basis',
            'season_128.basis',
            'season_129.basis',
            'season_130.basis',
            'season_131.basis',
            'season_132.basis',
            'season_133.basis',
            'season_134.basis',
            'season_135.basis',
            'season_136.basis',
            'season_137.basis',
            'season_138.basis',
            'season_139.basis',
            'season_140.basis',
            'season_141.basis',
            'season_142.basis',
            'season_143.basis',
            'season_144.basis',
            'season_145.basis',
            'season_146.basis',
            'season_147.basis',
            'season_148.basis',
            'season_149.basis',
            'season_150.basis',
            'season_151.basis',
            'season_152.basis',
            'season_153.basis',
            'season_154.basis',
            'season_155.basis',
            'season_156.basis',
            'season_157.basis',
            'season_158.basis',
            'season_159.basis',
            'season_160.basis',
            'season_161.basis',
            'season_162.basis',
            'season_163.basis',
            'season_164.basis',
            'season_165.basis',
            'season_166.basis',
            'season_167.basis',
            'season_168.basis',
            'season_169.basis',
            'season_170.basis',
            'season_171.basis',
            'season_172.basis',
            'season_173.basis',
            'season_174.basis',
            'season_175.basis',
            'season_176.basis',
            'season_177.basis',
            'season_178.basis',
            'season_179.basis',
            'season_180.basis',
            'season_181.basis',
            'season_182.basis',
            'season_183.basis',
            'season_184.basis',
            'season_185.basis',
            'season_186.basis',
            'season_187.basis',
            'season_188.basis',
            'season_189.basis',
            'season_190.basis',
            'season_191.basis',
            'season_192.basis',
            'season_193.basis',
            'season_194.basis',
            'season_195.basis',
            'season_196.basis',
            'season_197.basis',
            'season_198.basis',
            'season_199.basis',
            'season_200.basis',
            'season_201.basis',
            'season_202.basis',
            'season_203.basis',
            'season_204.basis',
            'season_205.basis',
            'season_206.basis',
            'season_207.basis',
            'season_208.basis',
            'season_209.basis',
            'season_210.basis',
            'season_211.basis',
            'season_212.basis',
            'season_213.basis',
            'season_214.basis',
            'season_215.basis',
            'season_216.basis',
            'season_217.basis',
            'season_218.basis',
            'season_219.basis',
            'season_220.basis',
            'season_221.basis',
            'season_222.basis',
            'season_223.basis',
            'season_224.basis',
            'season_225.basis',
            'season_226.basis',
            'season_227.basis',
            'season_228.basis',
            'season_229.basis',
            'season_230.basis',
            'season_231.basis',
            'season_232.basis',
            'season_233.basis',
            'season_234.basis',
            'season_235.basis',
            'season_236.basis',
            'season_237.basis',
            'season_238.basis',
            'season_239.basis',
            'season_240.basis',
            'season_241.basis',
            'season_242.basis',
            'season_243.basis',
            'season_244.basis',
            'season_245.basis',
            'season_246.basis',
            'season_247.basis',
            'season_248.basis',
            'season_249.basis',
            'season_250.basis',
            'season_251.basis',
            'season_252.basis',
            'season_253.basis',
            'season_254.basis',
            'season_255.basis',
            'season_256.basis',
            'season_257.basis',
            'season_258.basis',
            'season_259.basis',
            'season_260.basis',
            'season_261.basis',
            'season_262.basis',
            'season_263.basis',
            'season_264.basis',
            'season_265.basis',
            'season_266.basis',
            'season_267.basis',
            'season_268.basis',
            'season_269.basis',
        ];
        this.seasonTextures = {};
    }

    setProgress(progress) {
        if (this.waterTween) {
            this.waterTween.stop();
        }
        this.userTransition = true;
        this.waterTween = new TWEEN.Tween(this).to({
            progress,
        }, 1000).easing(TWEEN.Easing.Quadratic.Out).onComplete(() => {
            this.userTransition = false;
        }).start();

        if (this.cloudTween) {
            this.cloudTween.stop();
        }
        this.cloudTween = new TWEEN.Tween(this).to({
            cloudSpeedFactor: 1500 * (progress - this.progress),
        }, 500).easing(TWEEN.Easing.Quadratic.Out).chain(new TWEEN.Tween(this).to({
            cloudSpeedFactor: 1,
        }, 500).easing(TWEEN.Easing.Quadratic.Out)).start();
    }

    getUserTransition() {
        return this.userTransition;
    }

    getProgress() {
        return this.progress;
    }

    setSpeed(speed) {
        this.speed = speed;
    }

    resume() {
        this.running = true;
    }

    pause() {
        this.running = false;
    }

    setLayerOpacity(layer, opacity, duration = 500) {
        if (this[layer + 'fadeTween']) {
            this[layer + 'fadeTween'].stop();
        }
        this[layer + 'fadeTween'] = new TWEEN.Tween(this.layers[layer].material).to({
            opacity,
        }, duration).easing(TWEEN.Easing.Quadratic.Out).start();
    }

    zoomIn() {
        if (this.zoomTween) {
            this.zoomTween.stop();
        }
        this.zoomTween = new TWEEN.Tween(this).to({
            camera: {
                zoom: 2,
                rotation: {z: -Math.PI * 0.22},
                position: {x: -0.1, y: -0.13},
            },
            uniforms: {
                iCloudOpacity: {
                    value: 0,
                }
            }
        }, 1500).easing(TWEEN.Easing.Quadratic.Out).start();
    }

    zoomOut() {
        if (this.zoomTween) {
            this.zoomTween.stop();
        }
        this.zoomTween = new TWEEN.Tween(this).to({
            camera: {
                zoom: 1.5,
                rotation: {z: -Math.PI * 0.044},
                position: {x: 0, y: -0.16},
            },
            uniforms: {
                iCloudOpacity: {
                    value: 1,
                }
            }
        }, 1500).easing(TWEEN.Easing.Quadratic.Out).start();
    }

    zoomInBike() {
        if (this.zoomTween) {
            this.zoomTween.stop();
        }
        this.zoomTween = new TWEEN.Tween(this).to({
            camera: {
                zoom: 2,
                rotation: {z: -Math.PI * 0.22},
                position: {x: -0.22, y: -0.13},
            },
            uniforms: {
                iCloudOpacity: {
                    value: 0,
                }
            }
        }, 1500).easing(TWEEN.Easing.Quadratic.Out).start();
    }

    getRotation() {
        return this.camera.rotation.z;
    }

    getMousePosition() {
        return this.mousePosition;
    }

    createCharacterLabel(text) {
        const textCanvas = document.createElement('canvas');
        const ctx = textCanvas.getContext('2d');
        const size = 24;
        const font = size + 'px Sentinel';

        ctx.font = font;
        const lines = String(text).split('\n');
        let maxWidth = 0;
        for (let i = 0; i < lines.length; i++) {
            const width = Math.ceil(ctx.measureText(lines[i]).width);
            if (width > maxWidth) {
                maxWidth = width;
            }
        }
        textCanvas.width = maxWidth;
        textCanvas.height = size * (lines.length + 0.3);
        ctx.font = font;
        // ctx.textAlign = "center";
        // ctx.textBaseline = "middle";
        ctx.fillStyle = 'white';
        for (let i = 0; i < lines.length; i++) {
            ctx.fillText(lines[i], 0, size * (i+1));
        }

        const spriteMap = new THREE.Texture(ctx.getImageData(0, 0, textCanvas.width, textCanvas.height));
        spriteMap.minFilter = THREE.LinearFilter;
        spriteMap.generateMipmaps = false;
        spriteMap.needsUpdate = true;
        return {texture: spriteMap, lines: lines.length + 0.3};
    }

    drawPin(x, y, name, image, text, {align = 'top-right', scale = 0.025, callback = (n) => {}} = {}) {
        const loader = new THREE.TextureLoader();
        const pinMaterial = new THREE.SpriteMaterial({
            map: loader.load(image),
            transparent: true,
            opacity: 0,
        });

        const pinSprite = new THREE.Sprite(pinMaterial);
        pinSprite.position.x = x;
        pinSprite.position.y = y;
        pinSprite.scale.multiplyScalar(scale);

        this.layers['pin_' + name] = {material: pinMaterial, mesh: pinSprite};
        this.scene.add(pinSprite);

        const translation = window.translator.translateForKey(text);
        const {texture, lines} = this.createCharacterLabel(translation);

        const textMaterial = new THREE.SpriteMaterial({map: texture, transparent: true, opacity: 0});
        const sprite = new THREE.Sprite(textMaterial);
        this.layers['pintext_' + name] = {material: textMaterial, mesh: sprite};
        const pin = {text: text, scale: scale, sprite: sprite, align: align, x: x, y: y, lines: lines}
        this.pins[name] = pin;
        this.updatePinPosition(pin);
        this.scene.add(sprite);
        pinSprite.userData.onclick = () => callback(name);
        sprite.userData.onclick = () => callback(name);
    }

    updatePinPosition(pin) {
        const sprite = pin.sprite;
        const width = sprite.material.map.image.width;
        const height = sprite.material.map.image.height;
        const ratio = width / height;

        sprite.scale.set(ratio * pin.lines, pin.lines, 1);
        sprite.scale.multiplyScalar(pin.scale * 0.9);

        const xOffset = ratio * pin.lines * pin.scale;

        if (pin.align == 'top-right') {
            sprite.center.x = -0.5 / ratio / pin.lines;
            sprite.center.y = - 0.2 / pin.lines;
        } else if (pin.align == 'bottom-right') {
            sprite.center.x = -0.5 / ratio / pin.lines;
            sprite.center.y = 1.0 + 0.5 / pin.lines;
        } else if (pin.align == 'top') {
            sprite.center.y = -0.5 / pin.lines;
        } else if (pin.align == 'bottom') {
            sprite.center.y = 1.0 + 0.5 / pin.lines;
        } else if (pin.align == 'right') {
            sprite.center.x = -1 / ratio / pin.lines;
        }
        sprite.position.x = pin.x;
        sprite.position.y = pin.y;
    }

    updatePinsLanguage(lang) {
        for (const [k, v] of Object.entries(this.pins)) {
            const sprite = v.sprite;
            sprite.material.map.dispose();
            const translation = window.translator.translateForKey(v.text, lang);
            const {texture, lines} = this.createCharacterLabel(translation);
            sprite.material.map = texture;
            v.lines = lines;
            this.updatePinPosition(v);
        }
    }

    setPinOpacity(name, opacity, duration = 500) {
        this.setLayerOpacity('pin_' + name, opacity, duration);
        this.setLayerOpacity('pintext_' + name, opacity, duration);
    }

    screenToWorld(x, y) {
        this.camera.updateProjectionMatrix();
        const vec = new THREE.Vector3();
        vec.set((event.clientX / canvas.width) * 2 - 1, -(event.clientY / canvas.height) * 2 + 1, -1);
        vec.unproject(this.camera);
        return vec;
    }

    setupCanvas(canvas) {
        canvas.addEventListener('mousemove', (event) => {
            const rect = canvas.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const y = event.clientY - rect.top;
            this.mousePosition = this.screenToWorld(x, y);
        }, false);

        canvas.addEventListener('click', (event) => {
            const rect = canvas.getBoundingClientRect();
            const mouse = new THREE.Vector2()
            mouse.x = (event.clientX - rect.left) / rect.width * 2 - 1;
            mouse.y = -(event.clientY - rect.top) / rect.height * 2 + 1;
            const raycaster = new THREE.Raycaster();
            raycaster.setFromCamera(mouse, this.camera);
            for (const intersect of raycaster.intersectObjects(this.scene.children)) {
                const onclick = intersect.object.userData.onclick;
                const visible  = intersect.object.visible;
                if (onclick && visible) {
                    onclick();
                    event.stopPropagation();
                    break;
                }
            }
        }, false);
        this.renderer = new THREE.WebGLRenderer({canvas, alpha: true});
        this.renderer.autoClear = false;
        this.renderer.autoClearColor = false;
        this.renderer.autoClearDepth = false;
        this.renderer.autoClearStencil = false;

        this.camera = new THREE.OrthographicCamera(
            -1, // left
            1, // right
            1, // top
            -1, // bottom
            -1, // near,
            1 // far
        );
        this.camera.zoom = 1.5;
        this.camera.position.y = -0.16;
        // this.camera.rotation.z = -Math.PI * 0.044;

        const loader = new THREE.TextureLoader();
        const background = loader.load('podloga.jpg', (texture => {
            const rivers = loader.load('rijeke.png');
            const waters = loader.load('podloga_vode.png');
            const heightMap = loader.load('maska_plavljenja.png');
            const cloudMask = loader.load('cloud_mask.jpg');
            this.perlin = loader.load('tileperlin.jpg');
            this.perlin.wrapS = THREE.RepeatWrapping;
            this.perlin.wrapT = THREE.RepeatWrapping;

            const backgroundRatio = background.image.width / background.image.height;
            this.plane = new THREE.PlaneGeometry(2 * backgroundRatio, 2);

            this.uniforms = {
                iTime: {value: 0},
                iStormyness: {value: 0},
                iSnowyness: {value: 0},
                iHeight: {value: 0},
                iResolution: {value: new THREE.Vector3()},
                iChannel0: {value: heightMap},
                iBackground: {value: background},
                iRivers: {value: rivers},
                iWaters: {value: waters},
                iWaterAlpha: {value: this.waterAlpha},
                iFadeDepth: {value: this.fadeDepth},
                iFadeAmount: {value: this.fadeAmount},
                iWaterColor: {value: new THREE.Color(this.waterColor)},
                iZoom: {value: this.camera.zoom},
                iCloudOpacity: {value: 1},
                iCurrentSeason: {value: null},
                iNextSeason: {value: null},
                iSeasonProgress: {value: 0},
                iCloudMask: {value: cloudMask},
                iCloudTime: {value: 0},
                iMinorCloudTime: {value: 0},
                iPerlin: {value: this.perlin},
                iLightning: {value: this.lightning},
                iClouddark: {value: this.clouddark},
            };

            const fragmentShader = `
                uniform vec3 iResolution;
                uniform float iHeight;
                uniform float iWaterAlpha;
                uniform sampler2D iChannel0;
                uniform sampler2D iBackground;
                uniform sampler2D iRivers;
                uniform sampler2D iWaters;
                uniform float iFadeDepth;
                uniform float iFadeAmount;
                uniform vec3 iWaterColor;
                uniform float iLightning;

                uniform sampler2D iCurrentSeason;
                uniform sampler2D iNextSeason;
                uniform float iSeasonProgress;

                varying vec2 vTexCoord;

                void mainImage( out vec4 fragColor, in vec2 fragCoord )
                {
                    vec2 uv = vTexCoord;

                    vec4 heightFrag = texture2D(iChannel0, uv);
                    float height = texture2D(iChannel0, uv).r;
                    float waterLevel = min(iHeight, 0.99999);
                    vec4 waterColor = vec4(iWaterColor, 1.0);
                    vec4 groundColor = mix(texture2D(iCurrentSeason, uv), texture2D(iNextSeason, uv), iSeasonProgress);
                    float waterVisibility = step(height, waterLevel);

                    vec4 riversColor = texture2D(iRivers, uv);
                    vec4 watersColor = texture2D(iWaters, uv);

                    riversColor *= riversColor.a;
                    watersColor *= watersColor.a;
                    float smallWaterLevel = 1.0 - min(max(0.0, height - waterLevel)*iFadeDepth, iFadeAmount);
                    watersColor *= smallWaterLevel;

                    float fadeRegion = 0.1;
                    float fadeProgress = (fadeRegion - (height - waterLevel)) / fadeRegion;
                    float smallRiversColor = 1.0 - min(max(0.0, height + 0.02 - waterLevel)*iFadeDepth, 0.1);

                    vec4 finalWaterColor = vec4(0.0, 0.0, 0.0, 0.0);
                    finalWaterColor = mix(finalWaterColor, waterColor, watersColor.a);
                    finalWaterColor = mix(finalWaterColor, waterColor, riversColor.a);
                    finalWaterColor = mix(finalWaterColor, waterColor, step(height, waterLevel));

                    // fragColor = mix(groundColor, finalWaterColor, finalWaterColor.a * iWaterAlpha);
                    groundColor.rgb = groundColor.rgb * (1.0 + iLightning * 0.3);
                    fragColor = groundColor;
                }

                void main() {
                    mainImage(gl_FragColor, gl_FragCoord.xy);
                }
            `;

            const material = new THREE.ShaderMaterial({
                vertexShader,
                fragmentShader,
                uniforms: this.uniforms,
            });

            var loaded = 0;
            const layerCount = this.layerNames.length + this.seasonNames.length;

            const updateLoadCount = () => {
                loaded += 1;
                document.title = 'Loading: ' + (loaded / layerCount * 100).toFixed(1) + '%';
            }

            this.scene = new THREE.Scene();
            this.scene.add(new THREE.Mesh(this.plane, material));
            for (const layerName of this.layerNames) {
                const material = new THREE.MeshBasicMaterial({
                    map: loader.load(layerName, (texture) => {
                        updateLoadCount();
                    }),
                    transparent: true,
                    opacity: 0,
                });

                const layerNameWithoutExtension = layerName.substring(0, layerName.lastIndexOf('.'));
                const [name, pos] = layerNameWithoutExtension.split('@');

                const mesh = new THREE.Mesh(this.plane, material);
                this.layers[name] = {material, mesh};
                this.scene.add(mesh);

                if (pos) {
                    const [left, top, width, height] = pos.split(',').map(Number);
                    mesh.position.x = left * 2 * backgroundRatio;
                    mesh.position.y = -top * 2;
                    mesh.scale.x = width;
                    mesh.scale.y = height;
                }
            }

            // this.layers['granice'].material.opacity = 0.5;

            const basisLoader = new THREE.BasisTextureLoader();
            basisLoader.setTranscoderPath('basis/');
            basisLoader.detectSupport(this.renderer);


            for (const seasonName of this.seasonNames) {
                basisLoader.load(seasonName, (texture) => {
                    updateLoadCount();
                    this.seasonTextures[seasonName] = texture;
                });
            }

            const cloudMaterial = new THREE.ShaderMaterial({
                transparent: true,
                vertexShader,
                fragmentShader: `                uniform vec3 iResolution;
                uniform float iHeight;
                uniform float iWaterAlpha;
                uniform sampler2D iChannel0;
                uniform sampler2D iBackground;
                uniform sampler2D iRivers;
                uniform sampler2D iWaters;
                uniform sampler2D iCloudMask;
                uniform sampler2D iPerlin;
                uniform float iFadeDepth;
                uniform float iFadeAmount;
                uniform vec3 iWaterColor;
                uniform float iTime;
                uniform float iStormyness;
                uniform float iSnowyness;
                uniform float iClouddark;
                uniform float iZoom;
                uniform float iCloudOpacity;
                uniform float iCloudTime;
                uniform float iMinorCloudTime;
                uniform float iCloudSpeedFactor;
                uniform float iLightning;

                varying vec2 vTexCoord;

                #define NUM_NOISE_OCTAVES 5

                float noise(vec2 x) {
                    return texture2D(iPerlin, x*0.004).r;
                }

                float fbm(vec2 x) {
                    float v = 0.0;
                    float a = 0.5;
                    vec2 shift = vec2(100);
                    // Rotate to reduce axial bias
                    mat2 rot = mat2(cos(0.5), sin(0.5), -sin(0.5), cos(0.50));
                    for (int i = 0; i < NUM_NOISE_OCTAVES; ++i) {
                        v += a * noise(x);
                        x = rot * x * 2.0 + shift;
                        a *= 0.5;
                    }
                    return v;
                }

                float highpass(float v, float f) {
                    return (v*step(f, v)-f) / (1.0 - f) * 1.2;
                }

                vec4 clouds(vec2 uv) {
                    vec2 pos = uv*20.0;
                    vec2 direction = normalize(vec2(1, -1.5));
                    float base = fbm(pos + direction*iCloudTime);
                    float move = fbm(pos + direction*iMinorCloudTime);
                    float cloud = highpass(base*0.6 + move*0.4, 0.5);

                    vec3 col = vec3(cloud);
                    float fCloudMask = texture2D(iCloudMask, uv).r;
                    float cloudLightness = 1.0 - iClouddark * 0.4;
                    vec4 cloudColor = vec4(cloudLightness, cloudLightness, cloudLightness, cloud*iCloudOpacity*1.5*fCloudMask);
                    cloudColor.rgba = cloudColor.rgba * (1.0 + iLightning);
                    cloudColor.a = cloudColor.a * (1.0 + iLightning * 1.1);
                    return cloudColor;
                }

                float snow(vec2 uv, float scale)
                {
                    float time = iTime * 0.0005;
                    float w=smoothstep(1.,0.,-uv.y*(scale/10.));if(w<.1)return 0.;
                    uv += time/scale;
                    uv.y += time*2.0/scale;
                    //uv.x+=sin(uv.y+time*.5)/scale;
                    uv*=scale;
                    vec2 s = floor(uv);
                    vec2 f = fract(uv);
                    vec2 p;
                    float k = 3.;
                    float d;
                    p = .5 + .35 * sin(11.*fract(sin((s+p+scale)*mat2(7,3,6,5))*5.))-f;
                    d = length(p);
                    k = min(d,k);
                    k = smoothstep(0.,k,sin(f.x+f.y)*0.02);
                    return k*w;
                }

                float snowc(vec2 uv) {
                    float s = 0.0;
                    s += snow(uv, 60.0);
                    s += snow(uv, 50.0);
                    s += snow(uv, 40.0);
                    return s * iSnowyness;
                }


                float rainf(vec2 uv) {
                    float time = iTime * 0.0005;
                    vec2 newUV = uv * 3.5;
                    newUV.x += time*0.6;
                    newUV.y += time*3.;
                    float strength = 0.7 * iStormyness;

                    float rain = noise(vec2(newUV.x*100.0, -newUV.y*60.0+newUV.x*400.0-20.0*strength));
                    rain = strength-rain;
                    rain = clamp(rain, 0., 0.5)*0.5;
                    return rain;
                }

                void mainImage( out vec4 fragColor, in vec2 fragCoord )
                {
                    vec2 uv = vTexCoord;

                    vec4 cloudColor = clouds(uv);

                    vec2 cloudDistance = vec2(0.01, 0.04);

                    vec4 thereCloud = clouds(uv + cloudDistance);
                    vec4 thereCloud2 = clouds(uv + cloudDistance / 2.0);
                    float thereCloudAlpha = max(thereCloud.a, thereCloud2.a);

                    fragColor = cloudColor;

                    if (iSnowyness > 0.0) {
                        vec4 snowColor = vec4(1., 1., 1., snowc(uv) * (thereCloudAlpha - 0.1) * step(0.1, thereCloudAlpha) * 2.0);
                        if (snowColor.a > cloudColor.a) {
                            fragColor = snowColor;
                        }
                    }

                    if (iStormyness > 0.0) {
                        float rain = rainf(uv);
                        vec4 rainColor = vec4(1., 1., 1., rain * (thereCloudAlpha - 0.2) * step(0.2, thereCloudAlpha) * 3.0);
                        if (rainColor.a > cloudColor.a) {
                           fragColor = rainColor;
                        }
                    }

                    //fragColor = vec4(noise(uv*0.2));
                    //fragColor = vec4(noise2(uv*30.0));
                }

                void main() {
                    mainImage(gl_FragColor, gl_FragCoord.xy);
                }`,
                uniforms: this.uniforms,
            });

            const cloudPlane = new THREE.Mesh(this.plane, cloudMaterial);
            this.layers['clouds'] = {material: cloudMaterial, mesh: cloudPlane};
            // this.scene.add(cloudPlane);
            this.cloudScene = new THREE.Scene();
            this.cloudScene.add(cloudPlane);

            this.drawPins();
            requestAnimationFrame((time) => this.render(time));
        }));
    }

    resizeRendererToDisplaySize(renderer) {
        const canvas = this.renderer.domElement;
        const width = canvas.clientWidth;
        const height = canvas.clientHeight;
        const needResize = canvas.width !== width || canvas.height !== height;
        this.camera.right = canvas.width / canvas.height;
        this.camera.left = -canvas.width / canvas.height;
        this.camera.updateProjectionMatrix();
        if (needResize) {
            this.renderer.setSize(width, height, false);
        }
        return needResize;
    }

    render(timestamp) {
        if (this.previousTimestamp === null) {
            this.previousTimestamp = timestamp;
        }

        const timestampDelta = timestamp - this.previousTimestamp;

        if (this.running && !this.userTransition) {
            this.progress = (this.progress + timestampDelta * this.speed) % 1;
        }
        this.waterLevel = Math.abs((this.progress * 2) - 1);

        TWEEN.update();
        this.camera.updateProjectionMatrix();

        this.resizeRendererToDisplaySize(this.renderer);
        const canvas = this.renderer.domElement;
        this.uniforms.iResolution.value.set(canvas.width, canvas.height, 1);
        this.uniforms.iHeight.value = this.waterLevel;

        for (const [layerName, layer] of Object.entries(this.layers)) {
            layer.mesh.visible = Boolean(layer.material.opacity);
        }

        const maxSeasonIndex = this.seasonNames.length - 1;
        const currentSeasonIndex = Math.trunc(this.progress * maxSeasonIndex) % maxSeasonIndex;
        const prevSeasonIndex = mod(currentSeasonIndex - 1, maxSeasonIndex);
        const nextSeasonIndex = (currentSeasonIndex + 1) % maxSeasonIndex;
        const nextNextSeasonIndex = (currentSeasonIndex + 2) % maxSeasonIndex;
        const seasonProgress = (this.progress * maxSeasonIndex) % 1;

        const prevTexture = this.seasonTextures[this.seasonNames[prevSeasonIndex]];
        if (prevTexture) {
            prevTexture.dispose();
        }

        if (this.progress < 0.72) {
            this.stormyness = 0;
        } else if (this.progress < 0.77) {
            this.stormyness = 1 / 0.05 * (this.progress - 0.72);
        } else if (this.progress < 0.85) {
            this.stormyness = 1;
        } else if (this.progress < 0.88) {
            this.stormyness = 1 / 0.03 * (0.88 - this.progress);
        } else {
            this.stormyness = 0;
        }

        try {
            if (this.stormyness == 0) {
                document.getElementById('rainAmbient').pause();
                document.getElementById('rainAmbient').currentTime = 0;
            } else {
                document.getElementById('rainAmbient').volume = this.stormyness * 0.8;
                document.getElementById('rainAmbient').play();
            }
        } catch (err) {
            // play interrupted by pause
        }

        // if (this.progress > 0.75 && this.progress < 1) {
        //     document.getElementById('audioAmbient').volume = 0;
        // } else {
        //     document.getElementById('audioAmbient').volume = Math.min(1 - this.stormyness, 1 - this.snowyness);
        // }

        if (this.progress < 0.67) {
            this.clouddark = 0;
        } else if (this.progress < 0.72) {
            this.clouddark = 1 / 0.05 * (this.progress - 0.67);
        } else if (this.progress < 0.85) {
            this.clouddark = 1;
        } else if (this.progress < 0.88) {
            this.clouddark = 1 / 0.03 * (0.88 - this.progress);
        } else {
            this.clouddark = 0;
        }

        if (this.progress < 0.05) {
            this.snowyness = 1 / 0.05 * (0.05 - this.progress);
        } else if (this.progress > 0.1 && this.progress < 0.88) {
            this.snowyness = 0;
        } else if (this.progress < 0.90) {
            this.snowyness = 1 / 0.02 * (this.progress - 0.88);
        } else if (this.progress < 1.00) {
            this.snowyness = 1;
        } else {
            this.snowyness = 0;
        }

        if (!this.userTransition) {
            if (this.progress < 0.71) {
                this.lightning = 0;
            } else if (this.progress < 0.712) {
                document.getElementById('thunderAmbient').play();
                this.lightning = 0.7 / 0.002 * (this.progress - 0.71);
            } else if (this.progress < 0.74) {
                this.lightning = 0.7 / 0.028 * (0.74 - this.progress);
            } else {
                this.lightning = 0;
            }
        }

        this.uniforms.iCurrentSeason.value = this.seasonTextures[this.seasonNames[currentSeasonIndex]];
        this.uniforms.iNextSeason.value = this.seasonTextures[this.seasonNames[nextSeasonIndex]];
        this.uniforms.iSeasonProgress.value = seasonProgress;
        this.uniforms.iStormyness.value = this.stormyness;
        this.uniforms.iSnowyness.value = this.snowyness;
        this.uniforms.iLightning.value = this.lightning;
        this.uniforms.iClouddark.value = this.clouddark;

        this.cloudTime += timestampDelta / 30000 * this.cloudSpeedFactor
        this.minorCloudTime += timestampDelta / 30000 * Math.max(5, this.cloudSpeedFactor * 1.5);
        this.uniforms.iMinorCloudTime.value = this.minorCloudTime;
        this.uniforms.iCloudTime.value = this.cloudTime;

        const rtb = performance.now();
        this.renderer.render(this.scene, this.camera);
        this.renderer.render(this.cloudScene, this.camera);
        const rte = performance.now();
        $("#rt").text(rte - rtb);

        this.previousTimestamp = timestamp;

        this.uniforms.iTime.value = timestamp;
        this.uniforms.iZoom.value = this.camera.zoom;

        requestAnimationFrame((time) => this.render(time));
    }

    _activateButton(name) {
        $("[data-pin='"+ name +"']").addClass('active');
    }

    _checkActive(name) {
        return $("[data-pin='"+ name +"']").hasClass('active');
    }

    _callback(pin, name, type) {
        if (!this._checkActive(name)) {
            $('body').addClass('activeOverlay');
            window.menuControl.activatePopover(pin, name);
            this._activateButton(name);
        } else {
            window.menuControl.closePopover(type);
        }
    }

    drawPins() {
        const callbackVisitorOsekovo = (name) => { this._callback('visitor-osekovo', name, 'visitor'); };
        const callbackVisitorRepusnica = (name) => { this._callback('visitor-repusnica', name, 'visitor'); };
        const callbaclVisitorCigoc = (name) => { this._callback('visitor-cigoc', name, 'visitor'); };
        const callbackVisitorKrapje = (name) => { this._callback('visitor-krapje', name, 'visitor'); };

        const callbackViewpointLonjsko = (name) => { this._callback('viewpoint-lonjsko', name, 'visitor'); };
        const callbackViewpointTradicijskiRibolov = (name) => { this._callback('viewpoint-lonja', name, 'visitor'); };
        const callbackViewpointPticaKosac = (name) => { this._callback('viewpoint-repusnica', name, 'visitor'); };
        const callbackViewpointRoda = (name) => { this._callback('viewpoint-cigoc', name, 'visitor'); };
        const callbackViewpointRezac = (name) => { this._callback('viewpoint-rezac', name, 'visitor'); };
        const callbackViewpointZlicarka = (name) => { this._callback('viewpoint-zlicarka', name, 'visitor'); };

        const callbackDockDrenov = (name) => { this._callback('dock-drenov', name, 'visitor'); };
        const callbackDockPlesmo = (name) => { this._callback('dock-plesmo', name, 'visitor'); };

        this.drawPin(-0.7793748832592857, 0.3066285543162073, 'town_sisak', 'icon-circle.png', 'pin.sisak', {scale: 0.03});
        this.drawPin(-0.36734077090913386, 0.510310961134092, 'town_popovaca', 'icon-circle.png', 'pin.popovaca');
        this.drawPin(-0.11000501628402445, 0.2838106439183614, 'town_kutina', 'icon-circle.png', 'pin.kutina');
        this.drawPin(0.2172631253839499, -0.051972182816877294, 'town_novska', 'icon-circle.png', 'pin.novska');

        this.drawPin(-0.4231086025339723, 0.37341793784001187, 'visitor_osekovo', 'icon-visitor.png', 'pin.visitorOsekovo', {align: 'bottom-right', callback: callbackVisitorOsekovo});
        this.drawPin(-0.20911528812430882, 0.2901703095988627, 'visitor_repusnica', 'icon-visitor.png', 'pin.visitorRepusnica', {callback: callbackVisitorRepusnica});
        this.drawPin(-0.36584659545005893, 0.13545535174732043, 'visitor_cigoc', 'icon-visitor.png', 'pin.visitorCigoc', {callback: callbaclVisitorCigoc});
        this.drawPin(-0.03554964447455371, -0.17106790161405672, 'visitor_krapje', 'icon-visitor.png', 'pin.visitorKrapje', {callback: callbackVisitorKrapje});

        this.drawPin(-0.43104903306662257, 0.3537620361489635, 'viewpoint_osekovo_1', 'icon-viewpoint.png', 'pin.viewpointLonjsko', {align: 'top-right', callback: callbackViewpointLonjsko});
        this.drawPin(-0.42443667212217495, 0.3013455808540322, 'viewpoint_osekovo_2', 'icon-viewpoint.png', 'pin.viewpointTradicijskiRibolov', {align: 'bottom-right', callback: callbackViewpointTradicijskiRibolov});
        this.drawPin(-0.23584203608625776, 0.28767962773162503, 'viewpoint_repusnica', 'icon-viewpoint.png', 'pin.viewpointPticaKosac', {callback: callbackViewpointPticaKosac});
        this.drawPin(-0.36303003751769564, 0.14068844056273722, 'viewpoint_cigoc', 'icon-viewpoint.png', 'pin.viewpointRoda', {align: 'bottom-right', callback: callbackViewpointRoda});
        this.drawPin(-0.03644767855704292, -0.17032498321108624, 'viewpoint_krapje', 'icon-viewpoint.png', 'pin.viewpointRezac', {callback: callbackViewpointRezac});
        this.drawPin(-0.032692242253969384, -0.21881063937190862, 'viewpoint_drenov', 'icon-viewpoint.png', 'pin.viewpointZlicarka', {align: 'bottom-right', callback: callbackViewpointZlicarka});

        this.drawPin(-0.04174886483194118, -0.21644204892974733, 'dock_drenov', 'icon-dock.png', 'pin.dockDrenov', {align: 'bottom-right', callback: callbackDockDrenov});
        this.drawPin(-0.020985873785003745, -0.09147933809273043, 'dock_plesmo', 'icon-dock.png', 'pin.dockPlesmo', {callback: callbackDockPlesmo});

        this.drawPin(-0.499416569428238, 0.40865033061065725, 'town_struzec', 'icon-circle.png', 'pin.struzec', {scale: 0.02, align: 'bottom-right'});
        this.drawPin(-0.4216258265266434, 0.3728665888759237, 'town_osekovo', 'icon-circle-current.png', 'pin.osekovoHere', {scale: 0.02});
        this.drawPin(-0.2878257487359005, 0.3510851808634772, 'town_donja_gracanica', 'icon-circle.png', 'pin.donja_gracanica', {scale: 0.02, align: 'bottom-right'});
        this.drawPin(-0.20991823850620983, 0.3088503954434204, 'town_repusnica', 'icon-circle.png', 'pin.repusnica', {scale: 0.02});
        this.drawPin(-0.46985608712563204, 0.2437339556592765, 'town_veliko_svinjicko', 'icon-circle.png', 'pin.veliko_svinjicko', {scale: 0.02});
        this.drawPin(-0.3625048619214313, 0.13482691559704388, 'town_cigoc', 'icon-circle.png', 'pin.cigoc', {scale: 0.02});
        this.drawPin(-0.004667444574095749, 0.08970828471411899, 'town_kraljeva_velika', 'icon-circle.png', 'pin.kraljeva_velika', {scale: 0.02, align: 'bottom-right'});
        this.drawPin(-0.25359782185919877, 0.03369894982497082, 'town_suvoj', 'icon-circle.png', 'pin.suvoj', {scale: 0.02, align: 'bottom-right'});
        this.drawPin(-0.22248152469856083, 0.011917541812524274, 'town_lonja', 'icon-circle.png', 'pin.lonja', {scale: 0.02});
        this.drawPin(-0.10268378063010498, -0.0005289770517308834, 'town_trebez', 'icon-circle.png', 'pin.trebez', {scale: 0.02});
        this.drawPin(-0.07934655775962657, -0.08921042395954883, 'town_puska', 'icon-circle.png', 'pin.puska', {scale: 0.02});
        this.drawPin(-0.020225593154414634, -0.17011279657720735, 'town_krapje', 'icon-circle.png', 'pin.krapje', {scale: 0.02});
        this.drawPin(0.1104628549202645, -0.20278490859587706, 'town_jasenovac', 'icon-circle.png', 'pin.jasenovac', {scale: 0.02, align: 'bottom-right'});
        this.drawPin(-0.020225593154414634, -0.22923376118241928, 'town_drenov_bok', 'icon-circle.png', 'pin.drenov_bok', {scale: 0.02});
        this.drawPin(0.1851419681057955, -0.22767794632438737, 'town_kosturica', 'icon-circle.png', 'pin.kosturica', {scale: 0.02});
        this.drawPin(0.29093737845196427, -0.2743523920653443, 'town_mlaka', 'icon-circle.png', 'pin.mlaka', {scale: 0.02});
    }
}
