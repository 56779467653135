import { Scene } from './scene';
import { Menu } from './menu';
import { Interactions } from './interactions';
import Translator from "./translator";
import { Compass } from './compass';

function update() {
    compass.setTargetRotation(window.scene.getRotation());
    if (!window.scene.getUserTransition()) {
        $('#seasonRange').val(window.scene.getProgress());
    }
    requestAnimationFrame(update);
}



$(document).ready(function () {

    // $('body').on('touchmove', function(e) {
    //     e.preventDefault();
    // }), { passive: false };

    // document.addEventListener('touchmove', function(e) {
    //     return e.preventDefault();
    // }, { passive: false });

    document.addEventListener('contextmenu', event => event.preventDefault());

    const canvas = document.querySelector('#canvas');
    window.compass = new Compass(document.querySelector('#compass'));
    window.scene = new Scene(canvas);
    window.interactionsControl = new Interactions();
    window.menuControl = new Menu(window.scene);

    window.translator = new Translator();
    var translations = ['hr', 'en'];
    var fadeDuration = 500;

    for (var i = 0; i < translations.length; i++) {
        $.getJSON(`./i18n/${translations[i]}.json`, function(translation) {
            window.translator.add(translation.code, translation);
        });
    }

    $('.triggerMenu').on('click', function () {
        var menu = $(this).data('menu');
        var value = $(this).data('value');
        var layer = $(this).data('layer');
        var pins = $(this).data('pins');
        var opacity = $(this).data('opacity');

        window.menuControl.handleMenuClick(menu, value, layer, pins, opacity);
    });

    $('#btnBack').on('click', function() {
        $('#btnBack').prop('disabled', true);
        window.menuControl.handleBack();

        setTimeout(function() {
            $('#btnBack').prop('disabled', false);
        }, fadeDuration)
    });

    $('#btnHome').on('click', function() {
        window.menuControl.resetTable();
    });

    $('.activateVideo').on('click', function () {
        var id = $(this).data('id');

        window.menuControl.activateVideo(id);
    });

    $('.closeVideo').on('click', function () {
        $(this).find('video').get(0).pause();
        $(this).find('video').get(0).currentTime = 0;
        
        window.menuControl.closeVideo();
    });

    $('.activatePopover').on('click', function (evt) {
        evt.stopPropagation();
        if ($(this).hasClass('active')) {
            $(this).parent().find('.closePopover').click();
        } else {
            var id = $(this).data('id');
            var pin = $(this).data('pin');
            
            $('.activatePopover').removeClass('active');
            $(this).addClass('active');

            window.menuControl.activatePopover(id, pin);
        }
    });

    $('.closePopover').on('click', function (evt) {
        evt.stopPropagation();
        var type = $(this).data('type');

        window.menuControl.closePopover(type);
    });

    $('.activateRoute').on('click', function (evt) {
        evt.stopPropagation();
        if ($(this).hasClass('active')) {
            $(this).parent().find('.closeRoute').click();
        } else {
            var id = $(this).data('id');
            var type = $(this).data('type');
            
            $('.activateRoute').removeClass('active');
            $(this).addClass('active');
    
            window.menuControl.activateRoute(id, type);
        }
        
    });    

    $('.closeRoute').on('click', function (evt) {
        evt.stopPropagation();
        var type = $(this).data('type');

        window.menuControl.closeRoute(type);
    });

    $('body').on('click', function() {
        setTimeout(function() {
            if ($('body').hasClass('activeOverlay')) {
                $('body').removeClass('activeOverlay');
                $('.activatePopover.active').parent().find('.closePopover').click();
                $('.activateRoute.active').parent().find('.closeRoute').click();
                window.menuControl.closeVideo();
            }
        }, 10)
    });

    $('.triggerMenu').on('click', function() {
        $('#audioAmbient').get(0).play();
    });

    $(document).on('input', '#seasonRange', function() {
        window.scene.setProgress($(this).val());
    });

    requestAnimationFrame(update);
});
