export class Interactions {
    constructor() {

    }

    applyCompassRotation(rotation) {
        this.rotate($('#compass'), rotation);
    }

    rotate(elem, angle) {
        elem.css({ WebkitTransform: 'rotate(' + angle + 'rad)' });
        elem.css({ '-moz-transform': 'rotate(' + angle + 'rad)' });
    }
}
