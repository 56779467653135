function normalize(angle) {
    return angle % (2 * Math.PI);
}

function mod(a, m) {
    return a - Math.floor(a / m) * m;
}

function angleDifference(a, b) {
    const diff = b - a;
    return mod(diff + Math.PI, 2 * Math.PI) - Math.PI;
}

export class Compass {
    constructor(element) {
        this.element = element;
        this.speed = 0;
        this.previousTimestamp = null;
        this.rotation = 0;
        this.targetRotation = 0;
        this.previousRotation = 0;
        this.dragSpeed = 0;
        this.startAngle = 0;

        this.dragging = false;
        this.released = false;

        this.center = {
            x: 0,
            y: 0,
        };

        element.addEventListener("touchstart", (e) => {
            e.preventDefault();
            const rect = e.target.getBoundingClientRect()
            this.center = {
                x: rect.left + (rect.width / 2),
                y: rect.top + (rect.height / 2)
            };
            const x = e.touches[0].clientX - this.center.x;
            const y = e.touches[0].clientY - this.center.y;
            this.startAngle = Math.atan2(y, x);
            this.dragging = true;
            this.speed = 0;
            this.dragSpeed = 0;
        }, false);

        element.addEventListener("touchmove", (e) => {
            e.preventDefault();
            const x = e.touches[0].clientX - this.center.x;
            const y = e.touches[0].clientY - this.center.y;
            const d = Math.atan2(y, x);
            if (this.dragging) { 
                this.rotation += angleDifference(this.startAngle, d);
                this.startAngle = d;
            }
        }, false);

        stop = (e) => {
            if (this.dragging) {
                this.released = true;
            }
            this.dragging = false;
        };

        element.addEventListener("touchend", stop, false);
        requestAnimationFrame((t) => this.update(t));
    }

    setTargetRotation(rotation) {
        this.targetRotation = rotation;
    }

    update(timestamp) {
        if (this.previousTimestamp === null) {
            this.previousTimestamp = timestamp;
        }

        const timeDelta = (timestamp - this.previousTimestamp) / 1000;

        if (timeDelta < 0.1) {
            if (!this.dragging) {
                const acceleration = angleDifference(this.rotation, this.targetRotation) * 10;
                this.speed += acceleration * timeDelta;
                this.speed = this.speed - (this.speed * timeDelta * 0.8);
                this.rotation += this.speed * timeDelta;
            }

            if (this.dragging && timeDelta > 0) {
                this.dragSpeed = 0.3 * this.dragSpeed + 0.7 * angleDifference(this.previousRotation, this.rotation) / timeDelta;
                const maxSpeed = 2 * Math.PI * 5;
                this.dragSpeed = Math.max(Math.min(this.dragSpeed, maxSpeed), -maxSpeed);
            }

            if (this.released) {
                this.speed = this.dragSpeed;
                this.dragSpeed = 0;
                this.released = false;
            }
        }

        this.rotation = normalize(this.rotation);
        this.element.style.transform = "rotate(" + this.rotation + "rad)";
        this.previousTimestamp = timestamp;
        this.previousRotation = this.rotation;

        requestAnimationFrame((t) => this.update(t));
    }
}
